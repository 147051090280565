import Dexie from 'dexie';
import 'dexie-observable';
class Database extends Dexie {
    constructor() {
        super('RecipeRadar');
        this.version(20241219).stores({
            ingredients: '[recipe_id+product_id+index], recipe_id, product_id',
            products: 'id',
            recipes: 'id',
            starred: 'recipe_id',
            meals: '$$id, recipe_id',
            basket: 'product_id',
        });
    }
    minKey() { return Dexie.minKey; }
    maxKey() { return Dexie.maxKey; }
}
export const db = new Database();
